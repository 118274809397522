import React from "react"
import WhitepapersHelmet from "../components/SEO/WhitepapersHelmet"
import WhitepapersHero from "../sections/whitepapers/WhitepapersHero"
import WhitepapersListWithNewsletterForm from "../sections/whitepapers/WhitepapersListWithNewsletterForm"
import Layout from "../templates/Layout"

const Whitepapers = () => {
    return (
        <Layout>
            <div className="bg-horizen-hp-bg">
                <WhitepapersHelmet />
                <WhitepapersHero />
                <WhitepapersListWithNewsletterForm />
            </div>
        </Layout>
    )
}

export default Whitepapers
