import React from "react"

const WhitepapersHeroImg = () => {
    return (
        <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1901 19.9286H21.2578V42.0714H40.7435V25.4997L35.1901 19.9286ZM30.3275 35.8714H25.5181V34.1H30.3275V35.8714ZM36.067 32.3286H25.5181V30.5571H36.067V32.3286ZM36.067 28.7857H25.5181V27.0143H36.067V28.7857Z"
                fill="white"
            />
            <path
                d="M44.5957 0H0L12.4 12.3734H38.0237L49.6 23.9763V49.6L62 62V17.3954L44.5957 0Z"
                fill="white"
            />
            <path
                d="M49.6 49.6H12.4V12.3734L0 0V62H62L49.6 49.6Z"
                fill="url(#paint0_radial_2019_324)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_2019_324"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(13.702 12.8251) scale(52.0889)"
                >
                    <stop offset="0.1" stopColor="#26DB8D" />
                    <stop offset="0.26" stopColor="#23D29A" />
                    <stop offset="0.56" stopColor="#1ABBBB" />
                    <stop offset="0.89" stopColor="#0E9DE5" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default WhitepapersHeroImg
