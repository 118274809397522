import React from "react"
import NormalPageHero from "../../components/NormalPageHero"
import WhitepapersHeroImg from "../../components/Whitepapers/WhitepapersHeroImg"

import * as styles from "../../styles/whitepapers/whitepapers.module.css"

const WhitepapersHero = () => {
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.whitepapersHero}`}
        >
            <NormalPageHero
                img={<WhitepapersHeroImg />}
                titleKey="whitepapers.hero.title"
                subtitleKey="whitepapers.hero.p1"
            />
        </section>
    )
}

export default WhitepapersHero
