import React from "react"
import Button from "../Button"

type WhitepaperListItemProps = {
    title: string
    subtitle: string
    releaseDate: string
    desc: string
    ctaLabel: string
    ctaLink: string
}

const WhitepaperListItem = ({
    title,
    subtitle,
    releaseDate,
    desc,
    ctaLabel,
    ctaLink,
}: WhitepaperListItemProps) => {
    return (
        <div className="p-8 border hover:border-links flex flex-col h-full justify-between">
            <div className="space-y-2 flex flex-col">
                <span className="font-bold text-[22px] leading-7">{title}</span>
                <span className="text-lg leading-6">{subtitle}</span>
                <span className="text-horizen-grey">{releaseDate}</span>
                <p className="text-lg text-horizen-content-grey pb-4">{desc}</p>
            </div>
            <div>
                <Button href={ctaLink} label={ctaLabel} />
            </div>
        </div>
    )
}

export default WhitepaperListItem
