import React from "react"
import { Helmet } from "react-helmet"

const WhitepapersHelmet = () => {
    return (
        <Helmet>
            <title>Horizen - Whitepapers & Research</title>
            <meta
                property="og:title"
                content="Horizen - Whitepapers & Research"
            />
            <meta
                name="twitter:title"
                content="Horizen - Whitepapers & Research"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="View our whitepapers to learn how developers can fully customize their blockchains, including consensus type, speed, privacy, and crypto-economies."
            />
            <meta
                property="og:description"
                content="View our whitepapers to learn how developers can fully customize their blockchains, including consensus type, speed, privacy, and crypto-economies."
            />
            <meta
                name="twitter:description"
                content="View our whitepapers to learn how developers can fully customize their blockchains, including consensus type, speed, privacy, and crypto-economies."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/research.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/research.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="horizen whitepaper, zen whitepaper, zendoo whitepaper, zencash whitepaper, horizen research, horizen research, blockchain whitepaper, crypto whitepaper, zen cash whitepaper"
            />
            <link rel="canonical" href="https://www.horizen.io/research/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/research/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default WhitepapersHelmet
