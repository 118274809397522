import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import SectionHeading from "../../components/SectionHeading"
import WhitepaperListItem from "../../components/Whitepapers/WhitepaperListItem"
import ContentContainer from "../../templates/ContentContainer"

const WhitepapersList = () => {
    const { formatMessage } = useIntl()

    const renderWhitepaperListItems = () => {
        const whitepaperListItems = []
        let index = 0
        while (
            formatMessage({ id: `whitepapers.list.items.${index}.title` }) !==
            `whitepapers.list.items.${index}.title`
        ) {
            whitepaperListItems.push(
                <WhitepaperListItem
                    title={formatMessage({
                        id: `whitepapers.list.items.${index}.title`,
                    })}
                    subtitle={formatMessage({
                        id: `whitepapers.list.items.${index}.subtitle`,
                    })}
                    releaseDate={formatMessage({
                        id: `whitepapers.list.items.${index}.releaseDate`,
                    })}
                    desc={formatMessage({
                        id: `whitepapers.list.items.${index}.desc`,
                    })}
                    ctaLabel={formatMessage({
                        id: `whitepapers.list.items.${index}.ctaLabel`,
                    })}
                    ctaLink={formatMessage({
                        id: `whitepapers.list.items.${index}.ctaLink`,
                    })}
                />
            )
            index += 1
        }
        return whitepaperListItems.map(
            (whitepaperListItem) => whitepaperListItem
        )
    }

    return (
        <section className="text-white">
            <ContentContainer>
                <div className="space-y-5">
                    <div className="lg:max-w-[727px]">
                        <SectionHeading>
                            {formatMessage({
                                id: "whitepapers.list.sectionHeading",
                            })}
                        </SectionHeading>
                    </div>
                    <p className="text-lg text-horizen-content-grey leading-6 lg:max-w-[80%]">
                        {formatMessage(
                            {
                                id: "whitepapers.list.p1",
                            },
                            { br: <br /> }
                        )}
                    </p>
                    <p className="text-lg text-horizen-content-grey leading-6 lg:max-w-[80%]">
                        {formatMessage({
                            id: "whitepapers.list.p2",
                        })}
                    </p>
                </div>
                <div className="grid lg:grid-cols-2 gap-8 mt-20">
                    {renderWhitepaperListItems()}
                </div>
            </ContentContainer>
        </section>
    )
}

export default WhitepapersList
