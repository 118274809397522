import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import WhitepapersList from "./WhitepapersList"

import LatestUpdatesBackground from "../../assets/LatestUpdatesBackground.png"

const WhitepapersListWithNewsletterForm = () => {
    return (
        <div>
            <div>
                <WhitepapersList />
            </div>
            <div
                className="pt-48 lg:pt-60 -mt-20"
                style={{
                    backgroundImage: `url(${LatestUpdatesBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "50% 60%",
                }}
            >
                <NewsletterForm />
            </div>
        </div>
    )
}

export default WhitepapersListWithNewsletterForm
